html,
body {
  min-height: 100% !important;
  height: 100%;
  width: 100%;
  margin: 0;
}

#root {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}
